<template>
  <div style="position: relative;  z-index: 0;">
    <v-expansion-panels v-model="panel" tile>
      <v-expansion-panel readonly>
        <v-expansion-panel-header>
          <h4>Manage Vaccine Locations</h4>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content fluid>
          <v-row>
            <v-col>
              <span>Create/Edit Location:</span>
            </v-col>
          </v-row>
          <v-form ref="location">
          <v-row> 
            <v-col>
                <v-text-field
                  label="Location Display Name"
                  v-model="location.locationName"
                  :rules="required"
                  dense
                  outlined
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12">
                <v-text-field
                  label="Address 1"
                  :rules="required"
                  v-model="location.address"
                  dense
                  outlined
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                label="Address 2"
                v-model="location.address2"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="City"
                :rules="required"
                v-model="location.city"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-select
                :items="states"
                v-model="location.state"
                :rules="required"
                dense
                outlined
                label="State"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :rules="required"
                  label="Zip"
                  v-mask="'#####'"
                  dense
                  outlined
                  v-model="location.zip"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  label="Phone Number"
                  :rules="required"
                  v-model="location.phone"
                  dense
                  outlined
                  v-mask="'(###) ###-####'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-select
                :items="manufacturers"
                v-model="location.vaccineManufacturerId"
                @change="resetDoseType"
                :rules="required"
                dense
                outlined
                item-text="vaccineManufacturerName"
                item-value="vaccineManufacturerId"
                label="Vaccine Manufacturer"
                :disabled="location.id !== undefined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Location Dose Type (Select a Vaccine Manufacturer first):</span>
                <v-radio-group
                  v-model="locationVaccineDoseType"
                  row
                  :disabled="location.id !== undefined || location.vaccineManufacturerId === null"
                >
                  <v-radio
                    label="1st Dose Only"
                    value="FirstDoseOnly"
                  ></v-radio>
                  <v-radio
                    label="2nd Dose Only"
                    value="SecondDoseOnly"
                  ></v-radio>
                  <v-radio
                    label="1st &amp; 2nd Doses"
                    value="FirstAndSecondDose"
                  ></v-radio>
                  <v-radio
                    label="All Doses"
                    value="AllDoses"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="location.isLocationLocked"
                  label="Hide Location/Not Available to Schedule"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col>
              <v-btn class="ma-1" color="primary" @click="reset">Reset</v-btn>
              <v-btn class="ma-1" color="success" @click="saveLocation" :loading="loading">Save</v-btn>
              <v-btn color="error" @click="reviewDeleteSingle" :disabled="!location.id">Delete</v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <location-list
                @updateLocationForm="populateLocationForm"
                :key="renderKey"
              ></location-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

      <!-- location aopt release list dialog -->
    <v-dialog v-model="showLocationRelease" max-width="800px">
      <location-release-list :key="renderKeyLocationRelease" :locationId="selectedLocationId" @closeReleaseList="closeLocationRelease"></location-release-list>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      required: [
        v => !!v || 'Required Field'
      ],
      states: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
        'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
      panel: 0,
      location: {
        facilityId: null,
        market: null,
        division: null,
        locationName: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        phone: null,
        vaccineManufacturerId: null,
        vaccineDoseType: null,
        isLocationLocked: false
      },
      renderKey: 0,
      loading: false,
      renderKeyLocationRelease: 0,
      selectedLocationId: null,
      showLocationRelease: false,
      locationVaccineDoseType: null
    }
  },
  components: {
    locationList: () => import('./components/locationlist'),
    locationReleaseList: () => import('./components/locReleaseList')
  },
  props: {
    value: Boolean
  },
  watch: {
    facilities: {
      handler: function () {
        this.reset()
      }
    }
  },
  computed: {
    ...mapGetters('organization', ['facilities', 'selectedMarket', 'selectedDivision', 'selectedFacility']),
    ...mapGetters('vaccineManufacturers', ['manufacturers']),
    numberOfDoses: {
      get: function () {
        if (this.location.vaccineManufacturerId) {
          var vaccine = this.manufacturers.find(x => x.vaccineManufacturerId == this.location.vaccineManufacturerId)
          if (vaccine) {
            return vaccine.numberOfDoses
          }
          return null
        }
        return null
      }
    }
  },
  methods: {
    ...mapActions('vaccineLocations', ['insertLocation', 'updateLocation', 'deleteLocation','setLocations']),
    ...mapActions('vaccineManufacturers', ['getManufacturers']),
    saveLocation: function () {
      if (!this.$refs.location.validate()) {
        return false
      }
      this.loading = true
      // Add in the fields from the global state
      this.location.market = this.selectedMarket
      this.location.division = this.selectedDivision
      this.location.facilityId = this.selectedFacility
      this.location.vaccineDoseType = this.locationVaccineDoseType

      if (this.location.id) {
        this.updateLocation(this.location).then((response) => {
          if (response) {
            this.reset()
            this.renderKey++
            this.$emit('updateLocations')
          }
          this.loading = false
        })
      } else {
        this.insertLocation(this.location).then((response) => {
          if (response) {
            this.reset()
            this.renderKey++
            this.$emit('updateLocations')
          }
          this.loading = false
        })
      }
    },
    populateLocationForm: function (e) {
      if (e && e.length > 0) {
        this.location = { ...e[0] }
        this.locationVaccineDoseType = e[0].vaccineDoseType
      } else {
        this.reset()
      }
    },
    reset: function () {
      this.location = {
        facilityId: null,
        market: null,
        division: null,
        locationName: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        phone: null,
        vaccineManufacturerId: null,
        vaccineDoseType: null,
        isLocationLocked: false
      }
      this.$refs.location.reset()

      //Form reset nulls this out so reset it to a boolean to avoid errors on API
      this.location.isLocationLocked = false
      this.renderKey++;
    },
     reviewDeleteSingle() {
      this.renderKeyLocationRelease++;
      if(this.location.id) {
        this.selectedLocationId =  this.location.id
        this.showLocationRelease = true;
      }
    },
    closeLocationRelease() {
      this.selectedLocationId = null;
      this.showLocationRelease = false;
      this.location = {
        facilityId: null,
        market: null,
        division: null,
        locationName: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        phone: null,
        vaccineManufacturerId: null,
        vaccineDoseType: null,
        isLocationLocked: false
      }
      this.$refs.location.reset()
      this.$emit('updateLocations');
    },
    resetDoseType: function () {
      this.locationVaccineDoseType = null
    }
  },
  mounted () {
    this.getManufacturers();
  },
}
</script>

<style scoped>
.errorText {
  margin-left: 10px;
}
</style>
